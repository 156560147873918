.rocket {
    background: url('../image/rocket_logo/r.png') no-repeat;
    background-size: contain;
    width: 140px;
    height: 140px;
    cursor: -webkit-grab;
    will-change: transform;
    user-select: none;
  }
  
  .rocket:active {
    cursor: -webkit-grabbing;
  }
  